$(document).ready(function () {
    $('.income-input').keyup(function () {
        var $input = $(this);
        var $table = $input.closest('table.calc');
        var $values = $table.find('.percent-value');
        var value = parseInt(($input.val()));

        if (!$.isNumeric(value) || (value < 1)) {
            $values.each(function () {
                $(this).html('-- Ft');
            });
            return false;
        }

        $values.each(function () {
            var innerValue = parseInt(value * $(this).data('percent'));
            $(this).html(innerValue + ' Ft');
        });
    });
});